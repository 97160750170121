import { useState } from "react";
import { resetPassword } from "../../../api/auth";
import { useLocation } from "react-router-dom";
import "./../signup.css";
import Layout from "../../layout";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const location = useLocation();

  const onSubmit = async (e) => {
    e.preventDefault();

    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    try {
      const { data } = await resetPassword(token, newPassword);
      setMessage(data.message);
      setError("");
    } catch (error) {
      setError(error.response.data.message);
      setMessage("");
    }
  };

  const onChange = (e) => {
    setNewPassword(e.target.value);
  };

  return (
    <Layout>
      <section className="container Top">
        <div className="Bottom">
          <div className="CardContainer">
            <form onSubmit={onSubmit} className="">
              <div className="CardContainerTitle">
                <h2>Passwort zurücksetzen</h2>
              </div>
              <div className="">
                <label htmlFor="newPassword" className="form-label">
                  Neues Passwort:
                  <input
                    onChange={onChange}
                    type="password"
                    className="form-input"
                    id="newPassword"
                    name="newPassword"
                    value={newPassword}
                    placeholder="Neues Passwort"
                    required
                  />
                </label>
              </div>
              <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
              <div style={{ color: "green", margin: "10px 0" }}>{message}</div>
              <br />
              <button type="submit" className="btn-gl">
                Passwort zurücksetzen
              </button>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ResetPassword;
