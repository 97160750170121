import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const CreateEventForm = () => {
  const { userId } = useSelector((state) => state.user);

  const [eventData, setEventData] = useState({
    title: "",
    description: "",
    location: "",
    date: "",
    time: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/createevent/${userId}`,
        eventData
      );
      if (response.data.status === "success") {
        alert("Event erfolgreich erstellt");
      } else {
        alert("Fehler beim Erstellen des Events");
      }
    } catch (err) {
      console.error(err);
      alert("Fehler während Erstellen des Events");
    }
  };

  return (
    <div className="CardContainer">
      <form className="" onSubmit={handleSubmit}>
        <div className="CardContainerTitle">
          <h1>Event</h1>
        </div>
        <div>
          <label>Veranstaltung:</label>
          <input
            type="text"
            name="title"
            value={eventData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Beschreibung:</label>
          <textarea
            name="description"
            value={eventData.description}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div>
          <label>Ort:</label>
          <input
            type="text"
            name="location"
            value={eventData.location}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Datum:</label>
          <input
            type="date"
            name="date"
            value={eventData.date}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Beginn:</label>
          <input
            type="time"
            name="time"
            value={eventData.time}
            onChange={handleChange}
            required
          />
        </div>
        <button className="btn-gl" type="submit">
          Event erstellen
        </button>
      </form>
    </div>
  );
};

export default CreateEventForm;
