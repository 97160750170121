import React, { useState, useEffect } from "react";

const ResetWorkerRole = () => {
  const [workers, setWorkers] = useState([]);
  const [selectedWorkerId, setSelectedWorkerId] = useState("");
  const [message, setMessage] = useState("");

  // Funktion zum Abrufen aller "Worker" beim Laden der Komponente
  useEffect(() => {
    const fetchWorkers = async () => {
      try {
        const response = await fetch("/api/workers");
        const data = await response.json();

        if (Array.isArray(data)) {
          setWorkers(data);
        } else {
          console.error("Unexpected response format:", data);
          setWorkers([]);
        }
      } catch (error) {
        console.error("Error fetching workers:", error);
        setWorkers([]);
      }
    };

    fetchWorkers();
  }, []);

  // Funktion zum Zurücksetzen der Rolle eines ausgewählten Workers
  const handleResetRoleById = async () => {
    try {
      const response = await fetch("/api/reset-role/id", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: selectedWorkerId }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(`Rolle erfolgreich zurück gesetzt ${data.user_id}`);
        // Optional: Aktualisiere die Liste der Workers nach dem Zurücksetzen
        setWorkers(workers.filter((worker) => worker.user_id !== data.user_id));
        setSelectedWorkerId(""); // Setze das Dropdown zurück
      } else {
        setMessage(data.error || "Error resetting role");
      }
    } catch (error) {
      setMessage("Server error");
    }
  };

  return (
    <div className="CardContainer">
      <form>
        <div className="CardContainerTitle">
          <h2>Mitarbeiter entfernen</h2>
        </div>

        <div>
          <p>Mitarbeiter wählen:</p>
          <select
          className="form-select"
            value={selectedWorkerId}
            onChange={(e) => setSelectedWorkerId(e.target.value)}
          >
            <option value="" disabled>
              Select a worker
            </option>
            <br/>
            {workers.map((worker) => (
              <option key={worker.user_id} value={worker.user_id}>
                {worker.email} (ID: {worker.user_id})
              </option>
            ))}
          </select>
          <div className="">
          <button className="btn-gl" onClick={handleResetRoleById} disabled={!selectedWorkerId}>
            Aktualisieren
          </button>
          </div>
        </div>

        {message && <p>{message}</p>}
      </form>
    </div>
  );
};

export default ResetWorkerRole;
