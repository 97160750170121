import React from "react";
import { FaUserEdit, FaUpload } from "react-icons/fa";
import Layout from "./../../components/layout";
import "./dashboard.css";

const indexData = [
  {
    title: "Profil aktualisieren",
    icon: <FaUserEdit />,
    link: "/user/profile/data",
  },
  {
    title: "Profilbild aktualisieren",
    icon: <FaUpload />,
    link: "/user/profile/picture",
  },
];

const DashboardCard = ({ title, icon, link }) => {
  const cardContent = (
    <div className="card">
      <div className="card-icon">{icon}</div>
      <div className="card-title">{title}</div>
    </div>
  );

  return link ? (
    <a href={link} className="card-link">
      {cardContent}
    </a>
  ) : (
    cardContent
  );
};

const dashboard = () => {
  return (
    <Layout>
      <div className="Top">
        <div className="container">
          <div className="dashboardc">
            {indexData.map((item, index) => (
              <DashboardCard
                key={index}
                title={item.title}
                icon={item.icon}
                link={item.link}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default dashboard;
