//api/auth.js
import axios from "axios";

axios.defaults.withCredentials = true;

// Auth Api
//user Auth
export async function onRegistration(registrationData) {
  return await axios.post(`${process.env.REACT_APP_API_URL}/api/register`, registrationData);
}

export async function onLogin(loginData) {
  return await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, loginData);
}

export async function onLogout() {
  return await axios.get(`${process.env.REACT_APP_API_URL}/api/logout`);
}

export async function fetchProtectedInfo() {
  return await axios.get(`${process.env.REACT_APP_API_URL}/api/protected`);
}

export async function getUserNameByUserId(userId) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/getusernamebyuserid/${userId}`);
    return response.data.name;
  } catch (error) {
    console.error("Error fetching companies:", error);
    throw error;
  }
}

export async function getUserById(userId) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/getuserbyid/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Laden des Users:", error);
    throw error;
  }
}

//id Api
export async function findIdsByEmail(email) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/findidby/${email}`);
    return response.data.data;
  } catch (error) {
    console.error("Fehler beim Abrufen der IDs:", error);
    throw error;
  }
}

// OTP Api
export async function verifyOtp(otpData) {
  return await axios.post(`${process.env.REACT_APP_API_URL}/api/verify-otp`, otpData);
}

export async function resendOtp(data) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/resend-otp`, data);
    return response.data;
  } catch (error) {
    console.error("Fehler beim erneuten Senden des Tokens:", error);
    throw error;
  }
}

// Password Reset Api
export async function requestPasswordReset(email) {
  return await axios.post(`${process.env.REACT_APP_API_URL}/api/request-password-reset`, { email });
}

export async function resetPassword(token, newPassword) {
  return await axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password`, { token, newPassword });
}

export async function updateProfile(userId, profileData, token) {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/profile/${userId}`, profileData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren des Profils:", error);
    throw error;
  }
}

//Bild hochladen
export async function uploadImage(file, userId) {
  const formData = new FormData();
  formData.append("image", file);

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload/${userId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fehler beim Hochladen des Bildes:", error);
    throw error;
  }
}