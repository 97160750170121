import React from "react";
import { FaClipboardCheck, FaTasks, FaCheckCircle, FaNewspaper } from "react-icons/fa";
import Layout from "./../../components/layout";
import "./dashboard.css";

const indexData = [
  {
    title: "Formular",
    icon: <FaClipboardCheck />,
    link: "/user/form",
  },
  {
    title: "Aufträge",
    icon: <FaTasks />,
    link: "/user/auftrage",
  },
  {
    title: "Abgeschlossen",
    icon: <FaCheckCircle />,
    link: "/user/abgeschlossen",
  },
  {
    title: "New & Updates",
    icon: <FaNewspaper />,
    link: "/user/news",
  },
];

const DashboardCard = ({ title, icon, link }) => {
  const cardContent = (
    <div className="card">
      <div className="card-icon">{icon}</div>
      <div className="card-title">{title}</div>
    </div>
  );

  return link ? <a href={link} className="card-link">{cardContent}</a> : cardContent;
};

const dashboard = () => {
  return (
    <Layout>
      <section className="container">
        <div className="dashboardc">
          {indexData.map((item, index) => (
            <DashboardCard
              key={index}
              title={item.title}
              icon={item.icon}
              link={item.link}
            />
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default dashboard;
