import React from "react";
import "./Hero.css";
import IMG1 from "./../../../images/hero1.png";

const Hero = () => {
  return (
    <section className="hero-section">
      <div class="hero-wrapper-top">
        <div className="hero-wrapper">
          <div class="wrapper-1">
            <div className="hero-content1">
            <h1>
            Willkommen bei Youmas, <br/> Deine Lösung für effiziente Buchungen!
            </h1>
            </div>
          </div>
          <div class="wrapper-2">
            <div className="hero-content2">
              <img src={IMG1} alt="IMG1" className="IMG" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
