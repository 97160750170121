// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #b2adaa;
}

.btn-nav {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
}

.btn-nav:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}

@media screen and (max-width: 960px) {
  .btn-res {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Button.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,eAAe;EACf,WAAW;EACX,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,6BAA6B;EAC7B,WAAW;EACX,kBAAkB;EAClB,gCAAgC;EAChC,6BAA6B;AAC/B;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[":root {\r\n  --primary: #b2adaa;\r\n}\r\n\r\n.btn-nav {\r\n  padding: 8px 20px;\r\n  border-radius: 4px;\r\n  outline: none;\r\n  border: none;\r\n  font-size: 18px;\r\n  color: #fff;\r\n  cursor: pointer;\r\n  background-color: var(--primary);\r\n}\r\n\r\n.btn-nav:hover {\r\n  padding: 6px 18px;\r\n  transition: all 0.3s ease-out;\r\n  background-color: transparent;\r\n  color: #fff;\r\n  border-radius: 4px;\r\n  border: 2px solid var(--primary);\r\n  transition: all 0.3s ease-out;\r\n}\r\n\r\n@media screen and (max-width: 960px) {\r\n  .btn-res {\r\n    display: none;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
