import React, { useState } from "react";

const SetUserRole = () => {
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [message, setMessage] = useState("");

  const handleSetRoleByEmail = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/set-role/email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setMessage(`Role updated successfully for user: ${data.email}`);
      } else {
        setMessage(data.error || "Error updating role");
      }
    } catch (error) {
      setMessage("Server error");
    }
  };

  const handleSetRoleById = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/set-role/id`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: userId }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setMessage(`Role updated successfully for user ID: ${data.user_id}`);
      } else {
        setMessage(data.error || "Error updating role");
      }
    } catch (error) {
      setMessage("Server error");
    }
  };

  return (
    <div className="CardContainer">
      <form>
        <div className="CardContainerTitle">
          <h2>Mitarbeiter hinzufügen</h2>
        </div>

        <div>
          <p>Email:</p>
          <input
            className="form-input"
            type="email"
            placeholder="Gib die Benutzer E-Mail ein"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="btn-gl" onClick={handleSetRoleByEmail}>
            Aktualisieren
          </button>
        </div>

        <div className="Top1">
          <p>User ID:</p>
          <input
            className="form-input"
            type="text"
            placeholder="Gib die Benutzer-ID ein"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <button className="btn-gl" onClick={handleSetRoleById}>
            Aktualisieren
          </button>
        </div>
      </form>

      {message && <p>{message}</p>}
    </div>
  );
};

export default SetUserRole;
