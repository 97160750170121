import { useState } from "react";
import { requestPasswordReset } from "../../../api/auth";
import "./../signup.css";
import Layout from "../../layout";

const RequestPasswordReset = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await requestPasswordReset(email);
      setMessage(data.message);
      setError("");
    } catch (error) {
      setError(error.response.data.message);
      setMessage("");
    }
  };

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  return (
<Layout>
    <section className="container Top">
    <div className="Bottom">
      <div className="CardContainer">
        <form onSubmit={onSubmit} className="">
          <div className="CardContainerTitle">
            <h2>Passwort zurücksetzen</h2>
          </div>
          <div className="">
            <label htmlFor="email" className="form-label">
              Email Adresse:
              <input
                onChange={onChange}
                type="email"
                className="form-input"
                id="email"
                name="email"
                value={email}
                placeholder="User@test.com"
                required
              />
            </label>
          </div>
          <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
          <div style={{ color: "green", margin: "10px 0" }}>{message}</div>
          <br />
          <button type="submit" className="btn-gl">
            Link anfordern
          </button>
        </form>
      </div>
    </div>
    </section>
</Layout>
  );
};

export default RequestPasswordReset;
