import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import QRCode from "qrcode.react";

const EventQRCodeGenerator = () => {
  const { userId } = useSelector((state) => state.user);
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/geteventsbyuserid/${userId}`
        );

        const fetchedEvents = response.data.data.events;

        if (fetchedEvents && fetchedEvents.length > 0) {
          setEvents(fetchedEvents);
        } else {
          setError("Keine Events gefunden.");
        }
      } catch (err) {
        setError("Fehler beim Abrufen der Events.");
        console.error("Fehler beim Abrufen der Events:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [userId]);

  const handleEventSelection = (e) => {
    setSelectedEventId(e.target.value);
  };

  const eventUrl = selectedEventId
    ? `${process.env.REACT_APP_API_URL}/event/${selectedEventId}`
    : "";

  return (
    <form className="CardContainer">
      <div className="container CardContainer21">
        {loading && <div>Lädt...</div>}
        {error && <div>{error}</div>}

        {!loading && !error && (
          <div>
            <div className="CardContainerTitle">
              <h2>Wählen Sie ein Event aus:</h2>
            </div>
            <select
              className="form-select"
              value={selectedEventId}
              onChange={handleEventSelection}
            >
              <option value="">-- Wählen Sie ein Event --</option>
              {events.map((event) => (
                <option key={event.event_id} value={event.event_id}>
                  {event.title} (ID: {event.event_id})
                </option>
              ))}
            </select>
          </div>
        )}

        {selectedEventId && (
          <div style={{ marginTop: "20px" }}>
            <h3>QR-Code für das ausgewählte Event:</h3>
            <br />
            <QRCode value={eventUrl} />
            <div style={{ marginTop: "10px" }}>
              <a
                href={eventUrl}
                download={`event-${selectedEventId}-qrcode.png`}
              >
                QR-Code herunterladen
              </a>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default EventQRCodeGenerator;
