import React from "react";
import Layout from "../../../../components/layout";
import CreateEvent from "./createevent";
import QRCodeG from "./qrgenerator";

const Indexevent = () => {
  return (
    <Layout>
      <div className="Top Padding1">
        <CreateEvent />
        <br />
        <div className="Padding1">
          <QRCodeG />
        </div>
      </div>
    </Layout>
  );
};

export default Indexevent;
