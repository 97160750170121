import React from "react";
import { BiCalendarStar } from 'react-icons/bi';
import { HiUserAdd } from 'react-icons/hi';
import Layout from "./../../components/layout";
import "./../User/dashboard.css";

const indexData = [
  {
    title: "Event erstellen",
    icon: <BiCalendarStar />,
    link: "/dashboard/event",
  },
  {
    title: "Mitarbeiter hinzufügen",
    icon: <HiUserAdd />,
    link: "/dashboard/worker",
  },
];

const DashboardCard = ({ title, icon, link }) => {
  const cardContent = (
    <div className="card">
      <div className="card-icon">{icon}</div>
      <div className="card-title">{title}</div>
    </div>
  );

  return link ? (
    <a href={link} className="card-link">
      {cardContent}
    </a>
  ) : (
    cardContent
  );
};

const dashboard = () => {
  return (
    <Layout>
      <div className="Top">
        <div className="container">
          <div className="dashboardc">
            {indexData.map((item, index) => (
              <DashboardCard
                key={index}
                title={item.title}
                icon={item.icon}
                link={item.link}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default dashboard;
