import React from 'react'
import Layout from './../../components/layout'

const dashboard = () => {
  return (
    <Layout>
      <div className='CardContainerTop'>
        <div className='CardContainer'>
          öö
        </div>
      </div>
    </Layout>
  )
}

export default dashboard