//Home.js
import React from "react";
import Layout from "../components/layout";
import { useSelector } from "react-redux";
import QRCODES from "./User/components/home/qrcode";

const Home = () => {
  const { userRole } = useSelector((state) => state.role);
  const { userId } = useSelector((state) => state.user);

  return (
    <>
      <Layout>
        <div className="Top">
          <div className="CardContainer">
            <p>Benutzerrolle: {userRole}</p>
            <br />
            <p>Benutzer-ID: {userId}</p>
          </div>
          <br />
          <div className="Padding2">
            <QRCODES />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
