import React, { useEffect, useState } from "react";
import axios from "axios";

function getEventIdFromUrl() {
  const path = window.location.pathname;
  const pathParts = path.split("/");
  const eventId = pathParts[pathParts.length - 1];
  return eventId;
}

function formatDate(dateString) {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("de-DE", options);
}

function formatTime(timeString) {
  // Hier wird davon ausgegangen, dass die Zeit im Format "HH:MM:SS" oder "HH:MM" vorliegt
  const [hour, minute] = timeString.split(":");
  return `${hour}:${minute}`;
}

const EventDetails = () => {
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const eventId = getEventIdFromUrl();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getevents/${eventId}`
        );
        if (response.data.status === "success") {
          const eventData = response.data.data.events[0];

          // Formatieren des Datums und der Uhrzeit
          eventData.date = formatDate(eventData.date);
          eventData.time = formatTime(eventData.time);

          setEvent(eventData);
        } else {
          setError("Error fetching event");
        }
      } catch (err) {
        console.error(err);
        setError("An error occurred while fetching the event.");
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!event) {
    return <p>No event found</p>;
  }

  return (
    <div className="Top">
      <div className="CardContainer CardContainer21">
        <form>
          <div className="CardContainerTitle">
            <h2>{event.title}</h2>
          </div>
          <p>Beschreibung: {event.description}</p>
          <p>Ort: {event.location}</p>
          <p>Datum: {event.date}</p>
          <p>Zeit: {event.time}</p>
        </form>
      </div>
    </div>
  );
};

export default EventDetails;
