import React, { useEffect, useState } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import { useNavigate } from "react-router-dom";

function Scanner() {
  const [scanResult, setScanResult] = useState(null);
  const [manualSerialNumber, setManualSerialNumber] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const scanner = new Html5QrcodeScanner("reader", {
      qrbox: {
        width: 250,
        height: 250,
      },
      fps: 5,
    });

    let isScanning = true;

    scanner.render(success, error);

    function success(result) {
      if (isScanning) {
        scanner.clear();
        setScanResult(result);
        isScanning = false;

        const eventId = extractEventId(result);
        navigate(`/user/event/${eventId}`);
      }
    }

    function error(err) {
      console.warn(err);
    }

    const addButtonClass = () => {
      const buttons = document.querySelectorAll("#reader button");
      buttons.forEach((button) => {
        button.classList.add("btn-gl");
      });
    };

    addButtonClass();

    const observer = new MutationObserver(addButtonClass);
    observer.observe(document.getElementById("reader"), {
      childList: true,
      subtree: true,
    });

    return () => {
      scanner.clear();
      observer.disconnect();
    };
  }, [navigate]);

  function extractEventId(result) {
    const url = new URL(result);
    const pathname = url.pathname;
    return pathname.split("/").pop();
  }

  function handleManualSerialNumberChange(event) {
    setManualSerialNumber(event.target.value);
  }

  function handleManualSubmit() {
    if (manualSerialNumber) {
      navigate(`/user/event/${manualSerialNumber}`);
    }
  }

  return (
    <div className="">
      <div className="CardContainer21">
        <div className="CardContainer">
          <div className="CardContainerTitle">
            <h1>QR-Code Scanner</h1>
          </div>
          {scanResult ? (
            <div className="">
              <p>
                Erfolgreich: <a href={scanResult}>{scanResult}</a>
              </p>
              <p>Event Id: {scanResult.slice(-16)}</p>
            </div>
          ) : (
            <div>
              <div id="reader"></div>
              <p className="Text">Event Id:</p>
              <div className="">
                <input
                  className="form-input"
                  type="text"
                  value={manualSerialNumber}
                  onChange={handleManualSerialNumberChange}
                />
                <div className="">
                  <button className="btn-gl" onClick={handleManualSubmit}>
                    Öffnen
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Scanner;
