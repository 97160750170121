import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import Profile from "./Dropdown/Profile/ProfileDropdown";
import { FaHome, FaUser, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { FiHome, FiLogIn  } from "react-icons/fi";
import { FaEuroSign } from "react-icons/fa6";
import { FaRocketchat } from "react-icons/fa6";

import {Button} from "./Button";
import "./Navbar.css";
import { logout } from "./../../function/authService";
//import Socketio from "./Socketio/Socketio";

const Navbar = () => {
  const { isAuth } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  
  
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const { userRole } = useSelector((state) => state.role);
  const isAdmin = userRole === "admin";
  const isUser = userRole === "user";
  const isWorker = userRole === "worker";
  const isCompany = userRole === "company";

  return (
    <nav className="">
      <div className="navbar">
      {isAuth ? (
        <>
      <NavLink to="/home" className="navbar-logo">
        Logo
      </NavLink>
          <ul className="nav-menu">
            <li className="nav-item">
              <NavLink to="/home" className="nav-links">
                <FaHome className="nav-icon" />
                <span className="nav-text">Home</span>
              </NavLink>
            </li>
            {isUser && (
              <li className="nav-item">
                <NavLink to="/user/dashboard" className="nav-links">
                  <FaUser className="nav-icon" />
                  <span className="nav-text">Dashboard</span>
                </NavLink>
              </li>
            )}
            {isWorker && (
              <li className="nav-item">
                <NavLink to="/worker/dashboard" className="nav-links">
                  <FaUser className="nav-icon" />
                  <span className="nav-text">Dashboard</span>
                </NavLink>
              </li>
            )}
            {isCompany && (
              <li className="nav-item">
                <NavLink to="/company/dashboard" className="nav-links">
                  <FaUser className="nav-icon" />
                  <span className="nav-text">Dashboard</span>
                </NavLink>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item">
                <NavLink to="/admin/dashboard" className="nav-links">
                  <FaUser className="nav-icon" />
                  <span className="nav-text">Dashboard</span>
                </NavLink>
              </li>
            )}
            {isCompany && (
              <li className="nav-item">
                <NavLink to="/company/profile/create" className="nav-links">
                  <FaCog className="nav-icon" />
                  <span className="nav-text">Unternehmen</span>
                </NavLink>
              </li>
            )}
            <li className="nav-item">
              <NavLink to="/support" className="nav-links">
                <FaCog className="nav-icon" />
                <span className="nav-text">Support</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Startseite" className="nav-links" onClick={() => logout(dispatch)}>
                <FaSignOutAlt className="nav-icon" />
                <span className="nav-text"><div className="nav-text-l">Logout</div></span>
              </NavLink>
            </li>
          </ul>
          {/* <Socketio /> */}
          <Profile className="navbar-profile" />
        </>
      ) : (
        <>
          <NavLink to="/" className="navbar-logo" onClick={closeMobileMenu}>
            LOGO
          </NavLink>
          <div className="menu-icon" onClick={handleClick}>

          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
              <NavLink to="/startseite" className="nav-links">
                <FiHome className="nav-icon" />
                <span className="nav-text">Startseite</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/preise" className="nav-links">
                <FaEuroSign className="nav-icon" />
                <span className="nav-text">Preise</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/kontakt" className="nav-links">
                <FaRocketchat className="nav-icon" />
                <span className="nav-text">Kontakt</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/signup" className="nav-links">
                <FiLogIn className="nav-icon" />
                <span className="nav-text"><div className="nav-text-l">Anmelden </div></span>
              </NavLink>
            </li>
          </ul>
          <Button />
        </>
      )}
      </div>
    </nav>
  );
};

export default Navbar;
