// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notfound-wrapper {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notfound-card {
  max-width: 600px; /* Ändere die Breite nach Bedarf */
  margin: auto;
  padding: 20px;
  background-color: #ffffff; /* Hintergrundfarbe der Karte */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Schatten für die Karte */
}

.not-found {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.not-found h2 {
  font-size: 2rem; /* Ändere die Schriftgröße nach Bedarf */
  text-align: center;
}

.not-found p {
  font-size: 1rem; /* Ändere die Schriftgröße nach Bedarf */
  text-align: center;
}

.not-found-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Ändere den Abstand nach Bedarf */
  text-decoration: none;
}

.not-found-nav-item {
  text-decoration: none;
  color: #000000;
  font-size: 1rem; /* Ändere die Schriftgröße nach Bedarf */
  margin: 10px;
}

ul {
  list-style-type: none;
}

li {
  text-decoration: none;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/NotFound.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB,EAAE,kCAAkC;EACpD,YAAY;EACZ,aAAa;EACb,yBAAyB,EAAE,+BAA+B;EAC1D,wCAAwC,EAAE,2BAA2B;AACvE;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,eAAe,EAAE,wCAAwC;EACzD,kBAAkB;AACpB;;AAEA;EACE,eAAe,EAAE,wCAAwC;EACzD,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB,EAAE,mCAAmC;EACrD,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,eAAe,EAAE,wCAAwC;EACzD,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":[".notfound-wrapper {\n  padding-top: 50px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.notfound-card {\n  max-width: 600px; /* Ändere die Breite nach Bedarf */\n  margin: auto;\n  padding: 20px;\n  background-color: #ffffff; /* Hintergrundfarbe der Karte */\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Schatten für die Karte */\n}\n\n.not-found {\n  height: 50vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: 3rem;\n}\n\n.not-found h2 {\n  font-size: 2rem; /* Ändere die Schriftgröße nach Bedarf */\n  text-align: center;\n}\n\n.not-found p {\n  font-size: 1rem; /* Ändere die Schriftgröße nach Bedarf */\n  text-align: center;\n}\n\n.not-found-nav {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px; /* Ändere den Abstand nach Bedarf */\n  text-decoration: none;\n}\n\n.not-found-nav-item {\n  text-decoration: none;\n  color: #000000;\n  font-size: 1rem; /* Ändere die Schriftgröße nach Bedarf */\n  margin: 10px;\n}\n\nul {\n  list-style-type: none;\n}\n\nli {\n  text-decoration: none;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
