import React from "react";
import CPicture from "./picture";
import GetEvents from "./getevents";
import Layout from "./../../../components/layout";

const Indexevents = () => {
  return (
    <Layout>
      <div className="Padding3">
        <GetEvents />
        <br />
        <CPicture />
      </div>
    </Layout>
  );
};

export default Indexevents;
