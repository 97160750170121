// src/components/Profile.js
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { updateProfile } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../../../components/layout";

const Profile = () => {
  const { userId, token } = useSelector((state) => state.user);
  const [values, setValues] = useState({
    name: "",
    lastname: "",
    bday: "",
    gender: "",
    street: "",
    zipcode: "",
    city: "",
    country: "",
    number: ""
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      axios.get(`/api/getuserbyid/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          const data = response.data;
          setValues({
            name: data.name || "",
            lastname: data.lastname || "",
            bday: data.bday || "",
            gender: data.gender || "",
            street: data.street || "",
            zipcode: data.zipcode || "",
            city: data.city || "",
            country: data.country || "",
            number: data.number || ""
          });
        })
        .catch(error => console.error('Fehler beim Laden des Profils:', error));
    }
  }, [userId, token]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const updatedFields = Object.keys(values).reduce((acc, key) => {
      if (values[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    try {
      const { data } = await updateProfile(userId, updatedFields, token);

      setError("");
      setSuccess("Profil erfolgreich aktualisiert!");
      // Optionally navigate to another route
      // navigate("/profile");
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Profils:", error);

      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
      }
      setSuccess("");
    }
  };

  return (
    <Layout>
      <section className="container">
        <div className="CardContainerTop">
          <div className="Bottom">
            <div className="CardContainer">
              <form onSubmit={(e) => onSubmit(e)} className="">
                <div className="CardContainerTitle">
                  <h2>Profil aktualisieren</h2>
                </div>

                <div className="">
                  <label htmlFor="name" className="form-label">
                    Name:
                    <input
                      onChange={(e) => onChange(e)}
                      type="text"
                      className="form-input"
                      id="name"
                      name="name"
                      value={values.name}
                      placeholder="Max"
                      required
                    />
                  </label>
                </div>

                <div className="">
                  <label htmlFor="lastname" className="form-label">
                    Nachname:
                    <input
                      onChange={(e) => onChange(e)}
                      type="text"
                      className="form-input"
                      id="lastname"
                      name="lastname"
                      value={values.lastname}
                      placeholder="Mustermann"
                      required
                    />
                  </label>
                </div>

                <div className="">
                  <label htmlFor="bday" className="form-label">
                    Geburtsdatum:
                    <input
                      onChange={(e) => onChange(e)}
                      type="date"
                      className="form-input"
                      id="bday"
                      name="bday"
                      value={values.bday}
                      required
                    />
                  </label>
                </div>

                <div className="Geschlecht">
                  <label className="form-label">
                    Geschlecht:
                    <br />
                    <div className="G-Radio">
                      <label htmlFor="male">
                        Mann
                        <input
                          type="radio"
                          id="male"
                          name="gender"
                          value="M"
                          checked={values.gender === "M"}
                          onChange={(e) => onChange(e)}
                        />
                      </label>
                    </div>
                    <div className="G-Radio">
                      <label htmlFor="female">
                        Frau
                        <input
                          type="radio"
                          id="female"
                          name="gender"
                          value="F"
                          checked={values.gender === "F"}
                          onChange={(e) => onChange(e)}
                        />
                      </label>
                    </div>
                    <div className="G-Radio">
                      <label htmlFor="divers">
                        Divers
                        <input
                          type="radio"
                          id="divers"
                          name="gender"
                          value="D"
                          checked={values.gender === "D"}
                          onChange={(e) => onChange(e)}
                        />
                      </label>
                    </div>
                  </label>
                </div>

                <div className="">
                  <label htmlFor="street" className="form-label">
                    Straße:
                    <input
                      onChange={(e) => onChange(e)}
                      type="text"
                      className="form-input"
                      id="street"
                      name="street"
                      value={values.street}
                      placeholder="Musterstraße 123"
                      required
                    />
                  </label>
                </div>

                <div className="">
                  <label htmlFor="zipcode" className="form-label">
                    Postleitzahl:
                    <input
                      onChange={(e) => onChange(e)}
                      type="text"
                      className="form-input"
                      id="zipcode"
                      name="zipcode"
                      value={values.zipcode}
                      placeholder="12345"
                      required
                    />
                  </label>
                </div>

                <div className="">
                  <label htmlFor="city" className="form-label">
                    Stadt:
                    <input
                      onChange={(e) => onChange(e)}
                      type="text"
                      className="form-input"
                      id="city"
                      name="city"
                      value={values.city}
                      placeholder="Musterstadt"
                      required
                    />
                  </label>
                </div>

                <div className="">
                  <label htmlFor="country" className="form-label">
                    Land:
                    <input
                      onChange={(e) => onChange(e)}
                      type="text"
                      className="form-input"
                      id="country"
                      name="country"
                      value={values.country}
                      placeholder="Musterland"
                      required
                    />
                  </label>
                </div>

                <div className="">
                  <label htmlFor="number" className="form-label">
                    Telefonnummer:
                    <input
                      onChange={(e) => onChange(e)}
                      type="text"
                      className="form-input"
                      id="number"
                      name="number"
                      value={values.number}
                      placeholder="01234 5678912"
                      required
                    />
                  </label>
                </div>

                <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
                <div style={{ color: "green", margin: "10px 0" }}>{success}</div>
                <br />
                <button type="submit" className="btn-gl">
                  Absenden
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Profile;
