// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .avatar {
    border-radius: 50%;
    margin-right: 8px;
    height: 50px;
  }

  .profiledropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-menu {
    display: block;
    position: absolute;
    top: 110%;
    left: -52px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 800;
  }

  .dropdown-item {
    padding: 8px 16px;
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: #f1f1f1;
  }

  .icon {
    margin-right: 8px;
  }

  .logout-link {
    display: block;
    width: 100%;
    text-align: center;
  }

  @media (max-width: 800px) {
    .dropdown-menu {
      height: 100px;
      left: -33px;
    }

    .logout-link {
        display: none;
    }

    .dropdown-item {
      padding: 12px 16px;
    }

    .trigger {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Dropdown/Profile/profiledropdown.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,uBAAuB;IACvB,sBAAsB;IACtB,yCAAyC;IACzC,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE;MACE,aAAa;MACb,WAAW;IACb;;IAEA;QACI,aAAa;IACjB;;IAEA;MACE,kBAAkB;IACpB;;IAEA;MACE,aAAa;MACb,sBAAsB;MACtB,mBAAmB;IACrB;EACF","sourcesContent":[".trigger {\r\n    cursor: pointer;\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n\r\n  .avatar {\r\n    border-radius: 50%;\r\n    margin-right: 8px;\r\n    height: 50px;\r\n  }\r\n\r\n  .profiledropdown {\r\n    position: relative;\r\n    display: inline-block;\r\n  }\r\n\r\n  .dropdown-menu {\r\n    display: block;\r\n    position: absolute;\r\n    top: 110%;\r\n    left: -52px;\r\n    background-color: white;\r\n    border: 1px solid #ccc;\r\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\r\n    list-style: none;\r\n    padding: 0;\r\n    margin: 0;\r\n    z-index: 800;\r\n  }\r\n\r\n  .dropdown-item {\r\n    padding: 8px 16px;\r\n    cursor: pointer;\r\n  }\r\n\r\n  .dropdown-item:hover {\r\n    background-color: #f1f1f1;\r\n  }\r\n\r\n  .icon {\r\n    margin-right: 8px;\r\n  }\r\n\r\n  .logout-link {\r\n    display: block;\r\n    width: 100%;\r\n    text-align: center;\r\n  }\r\n\r\n  @media (max-width: 800px) {\r\n    .dropdown-menu {\r\n      height: 100px;\r\n      left: -33px;\r\n    }\r\n\r\n    .logout-link {\r\n        display: none;\r\n    }\r\n\r\n    .dropdown-item {\r\n      padding: 12px 16px;\r\n    }\r\n\r\n    .trigger {\r\n      display: flex;\r\n      flex-direction: column;\r\n      align-items: center;\r\n    }\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
