import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./gallery.css";

const ImageGallery = () => {
  const { eventId } = useParams();
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalImages, setTotalImages] = useState(0);
  const [email, setEmail] = useState('');
  const [showEmailInput, setShowEmailInput] = useState(false);
  const imagesPerPage = 20;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/images/${eventId}?page=${currentPage}&limit=${imagesPerPage}`
        );
        setImages(response.data.images);
        setTotalImages(parseInt(response.data.totalImages, 10));
        setLoading(false);
      } catch (err) {
        console.error("Error fetching images:", err);
        setError("Fehler beim Laden der Bilder.");
        setLoading(false);
      }
    };

    fetchImages();
  }, [eventId, currentPage]);

  const handleSelectImage = (gallery_id) => {
    setSelectedImages((prevSelected) =>
      prevSelected.includes(gallery_id)
        ? prevSelected.filter((id) => id !== gallery_id)
        : [...prevSelected, gallery_id]
    );
  };

  const handleDeleteImages = async () => {
    try {
      await Promise.all(
        selectedImages.map(async (gallery_id) => {
          await axios.delete(`${process.env.REACT_APP_API_URL}/api/images/${gallery_id}`);
        })
      );
      setImages((prevImages) =>
        prevImages.filter((image) => !selectedImages.includes(image.gallery_id))
      );
      setSelectedImages([]);
      setTotalImages(totalImages - selectedImages.length);
    } catch (err) {
      console.error("Error deleting images:", err);
      setError("Fehler beim Löschen der Bilder.");
    }
  };

  const handleSendEmail = async () => {
    const remainingImageIds = images.map(image => image.gallery_id);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/send-email`, {
        email,
        imageIds: remainingImageIds,
      });
      alert('E-Mail erfolgreich gesendet.');
    } catch (err) {
      console.error("Error sending email:", err);
      setError("Fehler beim Senden der E-Mail.");
    }
  };

  if (loading) return <p>Lade Bilder...</p>;
  if (error) return <p>{error}</p>;

  const totalPages = Math.ceil(totalImages / imagesPerPage);

  return (
    <div className="Top Bottom1">
      <div className="image-gallery">
        {images.length === 0 ? (
          <p>Keine Bilder gefunden.</p>
        ) : (
          images.map((image) => (
            <div
              key={image.gallery_id}
              className={`image-item ${selectedImages.includes(image.gallery_id) ? "selected" : ""}`}
              onClick={() => handleSelectImage(image.gallery_id)}
            >
              <img
                src={`${image.image_url}`}
                alt={`Event Bild ${image.gallery_id}`}
              />
            </div>
          ))
        )}
      </div>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            className={`page-button ${
              index + 1 === currentPage ? "active" : ""
            }`}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className="actions">
        <button className="delete-button" onClick={handleDeleteImages} disabled={selectedImages.length === 0}>
          Ausgewählte Bilder löschen
        </button>
        <button className="btn-gl" onClick={() => setShowEmailInput(true)}>
          Weiter
        </button>
      </div>
      {showEmailInput && (
        <div className="email-input">
          <input
            type="email"
            placeholder="E-Mail-Adresse eingeben"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="btn-gl" onClick={handleSendEmail}>
            Abschicken
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
