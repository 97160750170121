import React from "react";
import Layout from "./../../components/layout";
import Gallery from "./components/gallery";


const Dashboard = () => {

  return (
    <Layout>
      <div className="">
        <Gallery />
      </div>
    </Layout>
  );
};

export default Dashboard;
