import React from 'react'
import Hero from '../components/Home/Hero/Hero'
import Layout from '../components/layout'

const Startseite = () => {
  return (
    <Layout>
      <Hero />
    </Layout>
  )
}

export default Startseite